module.exports = [
    { id: 0, letter: "A" },
    { id: 1, letter: "B" },
    { id: 2, letter: "C" },
    { id: 3, letter: "Ç" },
    { id: 4, letter: "D" },
    { id: 5, letter: "E" },
    { id: 6, letter: "F" },
    { id: 7, letter: "G" },
    { id: 8, letter: "Ğ" },
    { id: 9, letter: "H" },
    { id: 10, letter: "I" },
    { id: 11, letter: "İ" },
    { id: 12, letter: "J" },
    { id: 13, letter: "K" },
    { id: 14, letter: "L" },
    { id: 15, letter: "M" },
    { id: 16, letter: "N" },
    { id: 17, letter: "O" },
    { id: 18, letter: "Ö" },
    { id: 19, letter: "P" },
    { id: 20, letter: "R" },
    { id: 21, letter: "S" },
    { id: 22, letter: "Ş" },
    { id: 23, letter: "T" },
    { id: 24, letter: "U" },
    { id: 25, letter: "Ü" },
    { id: 26, letter: "V" },
    { id: 27, letter: "Y" },
    { id: 28, letter: "Z" },
    { id: 29, letter: "Tümü" },
  ];