<template>
  <b-container class="mt-4" fluid="sm">
    <b-card header-tag="header" header-class="header">
      <template #header>
        <label v-text="'Personel Rehberi'" class="mr-2 text-white"></label>
      </template>
      <div>
        <div class="text-center">
          <b-button-group>
            <b-button
              v-for="button in alphabet"
              :key="button.id"
              size="sm"
              @click="getFilter(button.letter)"
              :class="getColor(button.letter)"
              >{{ button.letter }}</b-button
            >
          </b-button-group>
        </div>

        <b-row align-h="center">
          <b-form inline @submit="applyFilter">
            <b-form-input
              placeholder="TC / Ad-Soyad"
              v-model="personelFilter.where"
            ></b-form-input>
            <b-form-select
              id="table-select-mode-select"
              class="ml-3"
              v-model="personelFilter.role"
              :options="roleOptions"
            ></b-form-select>
            <b-button variant="secondary" class="ml-3" @click="applyFilter"
              >Gönder</b-button
            >
          </b-form>
          <b-pagination
            :total-rows="getPersonelAddressIdList.count"
            :per-page="1"
            v-model="currentPage"
            v-if="getPersonelAddressIdList.count > 0"
            @change="handlePageChange"
            class="mt-3 ml-4"
            aria-controls="my-table"
          >
            <template #first-text
              ><span class="text-secondary">&lt;&lt;</span></template
            >
            <template #prev-text
              ><span class="text-secondary">&lt;</span></template
            >
            <template #next-text
              ><span class="text-secondary">&gt;</span></template
            >
            <template #last-text
              ><span class="text-secondary">&gt;&gt;</span></template
            >

            <template #page="{ page, active }">
              <b v-if="active">{{ page }}</b>
              <i v-else>{{ page }}</i>
            </template>
          </b-pagination>
        </b-row>
        <br />
        <div v-if="getPersonelAddressIdList.count > 0">
          <b-row align-h="center">
            <b-col cols="auto">
              <b-img
                :src="getPersonelImage"
                thumbnail
                height="192"
                width="160"
              />
            </b-col>
            <b-col cols="8">
              <b-list-group>
                <b-list-group-item
                  ><b-row>
                    <b-col><b>TC Kimlik No:</b></b-col>
                    <b-col>{{ getPersonelAddresItem.ssn }}</b-col>
                  </b-row></b-list-group-item
                >
                <b-list-group-item
                  ><b-row>
                    <b-col><b>Ad Soyad:</b></b-col>
                    <b-col
                      ><router-link
                        :to="{
                          name: 'PersonelDetail',
                          params: { id: getPersonelAddresItem.id },
                        }"
                        >{{
                          getPersonelAddresItem.name +
                          " " +
                          getPersonelAddresItem.surname
                        }}</router-link
                      ></b-col
                    >
                  </b-row></b-list-group-item
                >
                <b-list-group-item
                  ><b-row>
                    <b-col><b>İş Telefon No:</b></b-col>
                    <b-col>{{
                      getPersonelAddresItem.contact.workPhoneNumber
                    }}</b-col>
                  </b-row></b-list-group-item
                >
                <b-list-group-item
                  ><b-row>
                    <b-col><b>İş Adresi:</b></b-col>
                    <b-col>{{
                      getPersonelAddresItem.contact.workAddress
                    }}</b-col>
                  </b-row></b-list-group-item
                >
                <b-list-group-item
                  ><b-row>
                    <b-col><b>Ev telefon no:</b></b-col>
                    <b-col>{{
                      getPersonelAddresItem.contact.homePhoneNumber
                    }}</b-col>
                  </b-row></b-list-group-item
                >
                <b-list-group-item
                  ><b-row>
                    <b-col><b>Ev adresi:</b></b-col>
                    <b-col>{{
                      getPersonelAddresItem.contact.homeAdress
                    }}</b-col>
                  </b-row></b-list-group-item
                >
                <b-list-group-item
                  ><b-row>
                    <b-col><b>Yaşadığı İl:</b></b-col>
                    <b-col>{{
                      cities.find(
                        (a) =>
                          a.value ==
                          getPersonelAddresItem.contact.cityWhereLocated
                      ).text
                    }}</b-col>
                  </b-row></b-list-group-item
                >
                <b-list-group-item
                  ><b-row>
                    <b-col><b>Yaşadığı İlçe:</b></b-col>
                    <b-col>{{ getPersonelAddresItem.contact.districtWhereLocated }}</b-col>
                  </b-row></b-list-group-item
                >
                <b-list-group-item
                  ><b-row>
                    <b-col><b>Cep Telefon No:</b></b-col>
                    <b-col>{{ getPersonelAddresItem.contact.gsm }}</b-col>
                  </b-row></b-list-group-item
                >
                <b-list-group-item
                  ><b-row>
                    <b-col><b>E Posta Adresi:</b></b-col>
                    <b-col>{{ getPersonelAddresItem.contact.email }}</b-col>
                  </b-row></b-list-group-item
                >
                <b-list-group-item
                  ><b-row>
                    <b-col><b>Web Sitesi:</b></b-col>
                    <b-col>{{ getPersonelAddresItem.contact.website }}</b-col>
                  </b-row></b-list-group-item
                >
              </b-list-group>
            </b-col>
          </b-row>
        </div>
        <div v-else><b-alert show>Kayıtlı Personel Bulunamadı</b-alert></div>
      </div>
    </b-card>
  </b-container>
</template>

<script>
import alphabet from "../../store/constants/alphabet";
import roleOptions from "@/store/constants/roleOptions.js";
import { mapGetters } from "vuex";
import cities from "../../store/constants/cities";
export default {
  computed: {
    ...mapGetters([
      "getPersonelAddressIdList",
      "getPersonelAddresItem",
      "getPersonelFilter",
      "getPersonelImage",
    ]),
    personelFilter: {
      get() {
        return this.getPersonelFilter;
      },
      set(value) {
        this.$store.commit("updatePersonelFilter", value);
      },
    },
  },
  created() {
    this.$store.dispatch("initPersonelAddressIdList");
  },
  data() {
    return {
      cities: cities,
      items: [],
      alphabet: alphabet,
      currentPage: 1,
      imageProps: { width: 400, height: 400 },
      actionModes: ["Seçiniz"],
      currentLetter: "Tümü",
      actionsSelected: [],
      actionsSelectMode: "Seçiniz",
      roleOptions: roleOptions,
    };
  },
  methods: {
    getFilter(letter) {
      this.currentLetter = letter;
      if (letter == "Tümü") {
        this.$store.dispatch("initPersonelAddressIdList", "");
      } else {
        this.$store.dispatch("initPersonelAddressIdList", letter);
      }
    },

    handlePageChange(value) {
      this.currentPage = value;
      this.$store.dispatch(
        "initpersonelAddresItem",
        this.getPersonelAddressIdList.rows[parseInt(value) - 1].id
      );
      this.$store.dispatch(
        "initPersonelImage",
        this.getPersonelAddressIdList.rows[parseInt(value) - 1].id
      );
    },
    getColor(value) {
      if (this.currentLetter == value) {
        return "btn-dark";
      }
    },
    applyFilter(event) {
      event.preventDefault();
      if (this.currentLetter == "Tümü") {
        this.$store.dispatch("initPersonelAddressIdList", "");
      } else {
        this.$store.dispatch("initPersonelAddressIdList", this.currentLetter);
      }
    },
    openDetail() {
      this.$router.push({
        name: "PersonelDetail",
        params: this.getPersonelAddresItem,
      });
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
  },
};
</script>

<style scoped>
.selection-field {
  max-width: 16rem;
  min-width: 16rem;
}
.align-right {
  margin-right: 1rem;
}
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>